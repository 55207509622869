
import { defineComponent, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "individual-client-payment-table-component",
  components: {},
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      payments: [
        {
          name: "",
          policyReference: "",
          serialNumber: "",
          invoiceNumber: "",
          premium: 0,
          paymentChannel: "",
          reference: "",
          dateCreated: "",
          metadata: { data: { status: "" } },
          policyPublicId: "",
          publicId: "",
        },
      ],
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      capitalize,
      displayDatatable,
    } = ResusableFunctions();

    onMounted(() => {
      displayDatatable(["basicExample"]);
    });

    return {
      useReusableNavigation,
      reinitialization,
      capitalize,
    };
  },
  mounted() {
    this.getPayments();
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    getPayments() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.FINANCE_PAYMENT_ROUTE)
          .then(({ data }) => {
            this.payments = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
