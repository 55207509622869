
import { defineComponent, onMounted } from "vue";
import PaymentsTable from "@/components/finance/PaymentsTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default defineComponent({
  name: "finance-payments",
  components: {
    PaymentsTable
  },
  data() {
    return {
      payments: []
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Payments");
      MenuComponent.reinitialization();
    });
  },
  created() {
    //Set page title
    document.title = "Payments List | " + process.env.VUE_APP_TITLE;
  }
});
